.ql-editor {
  height: 150px;
  max-height: 150px;
  overflow: auto;
}

.ql-container {
  height: 150px;
  max-height: 150px;
  overflow: auto;
}
